// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-caffeine-boost-js": () => import("./../../../src/pages/CaffeineBoost.js" /* webpackChunkName: "component---src-pages-caffeine-boost-js" */),
  "component---src-pages-cake-raid-js": () => import("./../../../src/pages/CakeRaid.js" /* webpackChunkName: "component---src-pages-cake-raid-js" */),
  "component---src-pages-deep-six-detritus-js": () => import("./../../../src/pages/DeepSixDetritus.js" /* webpackChunkName: "component---src-pages-deep-six-detritus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-renu-js": () => import("./../../../src/pages/Renu.js" /* webpackChunkName: "component---src-pages-renu-js" */),
  "component---src-pages-syntactic-sugar-js": () => import("./../../../src/pages/SyntacticSugar.js" /* webpackChunkName: "component---src-pages-syntactic-sugar-js" */),
  "component---src-pages-the-rock-js": () => import("./../../../src/pages/TheRock.js" /* webpackChunkName: "component---src-pages-the-rock-js" */)
}

